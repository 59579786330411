@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
}

@layer utilities {
  .launch-form-close-clip {
    clip-path: polygon(20% 30%, 81% 30%, 100% 100%, 0 100%);
  }

  .response-clip {
    clip-path: polygon(50% 40%, 10% 100%, 90% 100%);
  }

  .clip-path-1 {
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
  }

  .clip-path-2 {
    clip-path: polygon(
      0% 0%,
      0 100%,
      100% 100%,
      100% 0,
      87.5% 0,
      80% 15%,
      20% 15%,
      12.5% 0
    );
  }
  .clip-path-3 {
    clip-path: polygon(
      20% 0%,
      80% 0%,
      100% 0,
      100% 75%,
      88% 100%,
      20% 100%,
      0 100%,
      0 0
    );
  }
}

.PhoneInputCountrySelect {
  background-color: #041535;
}
.PhoneInputInput {
  background-color: #4f5b72;
}
